import Greeting from '../../components/greeting/Greeting';
import Header from '../../components/header/Header';

function Home() {
  return (
    <div>
      {/* <Header /> */}
      <Greeting />
    </div>
  );
}

export default Home;
